import React from "react";
import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image"
import moment from "moment";
import Layout from "../components/layout";
import Seo from "../components/seo";
import Parts from '../components/parts';
import _ from 'lodash';
import HeroImage from "../components/HeroImage/heroimage";
import { withPrefix } from "gatsby"
import MDRender from "../components/mdrender"
const isBrowser = typeof document !== "undefined"

import { getImage } from "gatsby-plugin-image"
import { convertToBgImage } from "gbimage-bridge"
import BackgroundImage from 'gatsby-background-image'
import ServiceMenu from '../components/servicemenu';



export default function SolutionPage({ data }) {


    let mapPagedata = _.merge(  _.pick( data.page, ['id','date','title','description','section','page','slug','options','keywords']),
                                _.pick( data.page.body.data, ['body']),
                                {image: data.page.image.localFile }
                              )
    let partsData = _.orderBy( data.page.parts.map( item => {
                  //console.debug( item );
                  return _.merge( _.pick( item, ['id','title','order','page','layouttype','partId','anchor','options']),
                                  { image: (item.image) ? item.image.localFile : null },
                                  { text: (item.text && item.text.data ) ? ( item.text.data.text ) : "" })
                }),["order"],["asc"]  )

    const useHero = ( mapPagedata.image !==null) && (mapPagedata.options) && (mapPagedata.options.useHero)
    const background = ( mapPagedata.image !==null) && (mapPagedata.options) && (mapPagedata.options.background)
    //console.debug(mapPagedata )

    if (useHero) { return (
      <Layout header="Page" >
        <Seo  title={ mapPagedata.title } descripton={mapPagedata.descripton}  keywords={mapPagedata.keywords} />
        <div className="site-container">
          <div className="container p-3">
              <HeroImage title={mapPagedata.title} description={mapPagedata.description} image={withPrefix(mapPagedata.image.childImageSharp.gatsbyImageData.images.fallback.src)} />
              <div className="details">
                <span className="date">
                  <i className="fas fa-calendar-alt"></i>{" "}
                    {moment(mapPagedata.date,  "YYYY-MM-DD").format("LL")}
                </span>
                <div>
                    <MDRender mdstring={mapPagedata.body} />
                </div>
                <Parts key="Parts" anchor="parts" parts={partsData}/>
              </div>
          </div>
        </div>
      </Layout>
    ) } else if (background) {

      const image = getImage(withPrefix(mapPagedata.image));
      const bgImage = convertToBgImage(image);
      return (
      <Layout header="Page" >
        <Seo  title={ mapPagedata.title } descripton={mapPagedata.descripton}  keywords={mapPagedata.keywords} />
        <div className="site-container">
          <div className="container">
            <div className="solution-page">
              <BackgroundImage        Tag="section"   {...bgImage}     preserveStackingContext       >
                <div className="solution-content text-white overlay overlay-dark">
                  <h2 className="title">{mapPagedata.title}</h2>
                  <h3 className="description">{mapPagedata.description}</h3>
                  <span className="date">
                    <i className="fas fa-calendar-alt"></i>{" "}
                      {moment(mapPagedata.date,  "YYYY-MM-DD").format("LL")}
                  </span>
                  <div className="row">
                      <div className="col solution-detail">
                          <GatsbyImage  className="solution-image"  image={image} alt="image" objectFit="cover" objectPosition="50% 50%" />
                      </div>
                      <div className="col solution-details">

                        <div className="solution-body ">
                            <MDRender mdstring={mapPagedata.body} />
                        </div>
                      </div> {/*solution-details */}
                  </div> {/* row */}
                  <div className="row solution-footer p-3">
                    <div className="col solution-parts">
                        <Parts key="Parts" anchor="parts" parts={partsData}/>
                    </div>
                    <ServiceMenu  key="ServiceMenu" title="Our Products and Services" ></ServiceMenu>
                  </div>
                </div> {/*solution-content*/}
              </BackgroundImage>
            </div> {/* solution-page */}
          </div>
        </div>
      </Layout>
    ) } else { return (
      <Layout header="Page" >
        <Seo  title={ mapPagedata.title } descripton={mapPagedata.descripton}  keywords={mapPagedata.keywords} />
        <div className="site-container">
          <div className="container">
                {mapPagedata.image ? ( <GatsbyImage image={mapPagedata.image.childImageSharp.gatsbyImageData} alt="image" objectFit="cover" objectPosition="50% 50%"  /> ) : ( <div className="no-image"></div> )}
                <div className="details">
                  <h1 className="title">{mapPagedata.title}</h1>

                  <span className="date">
                    <i className="fas fa-calendar-alt"></i>{" "}
                      {moment(mapPagedata.date,  "YYYY-MM-DD").format("LL")}
                  </span>
                  <div>
                    <h2>{mapPagedata.description}</h2>
                  </div>
                  <div>
                    <MDRender mdstring={mapPagedata.body} />
                  </div>
                  <Parts key="Parts" anchor="parts" parts={partsData}/>
                </div>
          </div>
        </div>
      </Layout>
    );
  };
}

export const pageQuery = graphql`
query ($id: String!) {
  page: strapiSolution(id: {eq: $id }) {
    id
    body {
      data {
        id
        body
      }
    }
    date
    title
    page
    description
    section
    slug
    options {
        useHero
        background
    }
    parts {
      id
      order
      page
      title
      text {
        data {
          text
        }
      }
      layouttype
      partId
      anchor
      options {
        parallax
        height
        centre
        centretitle
        margin        
      }
      image {
                localFile {
                  publicURL
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
    }
    image {
      id
      localFile {
        childImageSharp {
          gatsbyImageData
        }
      }
    }
  }


}
`;
